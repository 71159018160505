import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProfileComponent } from './components/profile/profile.component';
import { RouteGuardService } from './services/route-guard.service';
import { SellOverviewComponent } from './components/sell-overview/sell-overview.component';
import { CashauditComponent } from './components/cashaudit/cashaudit.component';


const routes: Routes = [
  {path: 'profile', component: ProfileComponent},
  {path: 'sell', component: SellOverviewComponent, canActivate: [RouteGuardService]},
  {path: 'cashaudit', component: CashauditComponent, canActivate: [RouteGuardService]},
  {path: '**', redirectTo: 'profile'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
