import { Component, OnInit } from '@angular/core';
import { LoginService } from 'src/app/services/login.service';
import { User } from 'src/app/models/user';

@Component({
  selector: 'app-profile-details',
  templateUrl: './profile-details.component.html',
  styleUrls: ['./profile-details.component.scss']
})
export class ProfileDetailsComponent implements OnInit {

  public user: User;

  constructor(private loginService: LoginService) { }

  ngOnInit(): void {
    this.user = this.loginService.getLoggedIn();
  }

}
