import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { User } from 'src/app/models/user';
import { LoginService } from 'src/app/services/login.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  users: User[];

  selectedUser: User;
  enteredPin: string;

  constructor(private userService: UserService, private loginServoce: LoginService, private router: Router) { }

  ngOnInit(): void {

    this.userService.getUsers().subscribe(users => {
      this.users = users;
    });

  }

  loginUser() {
    this.userService.getApiKey(this.selectedUser, this.enteredPin).subscribe(user => {
      this.loginServoce.setLoggedIn(user);
    });
    this.enteredPin = '';
  }

  logoutUser() {
    this.loginServoce.setLoggedIn(null);
  }

  isLoggedIn(): boolean {
    return this.loginServoce.isLoggedIn();
  }

}
