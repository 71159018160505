import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER } from '@angular/core';
import {LOCALE_ID} from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeDE from '@angular/common/locales/de';

import { NavComponent } from './components/nav/nav.component';
import { ProfileComponent } from './components/profile/profile.component';

import {MatCardModule} from '@angular/material/card';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatButtonModule} from '@angular/material/button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SellOverviewComponent } from './components/sell-overview/sell-overview.component';
import { ContentComponent } from './components/content/content.component';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatIconModule} from '@angular/material/icon';
import { ProfileDetailsComponent } from './components/profile-details/profile-details.component';
import {MatListModule} from '@angular/material/list';
import {MatDialogModule} from '@angular/material/dialog';
import {MatTableModule} from '@angular/material/table';
import { AddTicketDialogComponent } from './components/add-ticket-dialog/add-ticket-dialog.component';
import {MatRadioModule} from '@angular/material/radio';
import { BaseDataService } from './services/base-data.service';
import {MatSortModule} from '@angular/material/sort';
import { BarcodeScannerComponent } from './components/barcode-scanner/barcode-scanner.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';

import { DeviceDetectorModule } from 'ngx-device-detector';
import { ToastrModule } from 'ngx-toastr';
import { CashauditComponent } from './components/cashaudit/cashaudit.component';

export function initializeApp(baseDataService: BaseDataService) {
  return (): Promise<any> => {
    return Promise.resolve();
  };
}

registerLocaleData(localeDE);

@NgModule({
  declarations: [
    AppComponent,
    NavComponent,
    ProfileComponent,
    SellOverviewComponent,
    ContentComponent,
    ProfileDetailsComponent,
    AddTicketDialogComponent,
    BarcodeScannerComponent,
    CashauditComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    DeviceDetectorModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 8000,
      progressBar: true,
      positionClass: 'toast-top-center',
    }),

    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatButtonModule,
    MatToolbarModule,
    MatIconModule,
    MatListModule,
    MatDialogModule,
    MatTableModule,
    MatRadioModule,
    MatSortModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
  ],
  providers: [
    BaseDataService,
    { provide: APP_INITIALIZER, useFactory: initializeApp, deps: [BaseDataService], multi: true},
    { provide: LOCALE_ID, useValue: 'de' },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
