import { Component, OnInit, ViewChild } from '@angular/core';
import { BaseDataService } from 'src/app/services/base-data.service';
import { Ticket } from 'src/app/models/ticket';
import { MatDialog } from '@angular/material/dialog';
import { AddTicketDialogComponent } from '../add-ticket-dialog/add-ticket-dialog.component';
import { MatTable } from '@angular/material/table';
import { AddTicketDialogData } from 'src/app/models/add-ticket-dialog-data';
import { Show } from 'src/app/models/show';
import { Converter } from 'src/app/utils/converter';
import { DeviceDetectorService } from 'ngx-device-detector';
import { isGeneratedFile } from '@angular/compiler/src/aot/util';
import { Validator } from 'src/app/utils/validator';
import { ActivationService } from 'src/app/services/activation.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-sell-overview',
  templateUrl: './sell-overview.component.html',
  styleUrls: ['./sell-overview.component.scss']
})
export class SellOverviewComponent implements OnInit {

  public converter: Converter = new Converter();

  public tickets: Ticket[] = [];
  public displayedColumns = ['show', 'visitor', 'id', 'price', 'actions'];
  @ViewChild(MatTable) ticketTable: MatTable<Ticket>;

  public payCheck: boolean;

  public activationInProgress = false;

  private lastSelectedShow: Show = null;

  constructor(private baseDataService: BaseDataService,
              private dialogHandler: MatDialog,
              private deviceDetector: DeviceDetectorService,
              private activationService: ActivationService,
              private toastrService: ToastrService) { }

  ngOnInit(): void {
    // this.openTicketDialog();
  }

  public openTicketDialog() {
    const data = new AddTicketDialogData();
    data.show = this.lastSelectedShow;
    data.sellOverview = this;

    const ticketDialog = this.dialogHandler.open(AddTicketDialogComponent, {
      width: '80%',
      disableClose: true,
      data
    });

    ticketDialog.afterClosed().subscribe(ticket => {
      if (ticket) {
        this.tickets.push(ticket);
        this.lastSelectedShow = ticket.show;
        this.ticketTable.renderRows();
      }
    });
  }

  public isTicketAlreadyAdded(ticketId: number) {
    for (const ticket of this.tickets) {
      if (ticket.id === ticketId) {
        return true;
      }
    }
    return false;
  }

  public removeTicket(ticket: Ticket) {
    const ticketIndex = this.tickets.indexOf(ticket);
    if (ticketIndex > -1) {
      this.tickets.splice(ticketIndex);
    }
    this.ticketTable.renderRows();
  }

  public activateTickets() {
    this.activationInProgress = true;
    this.activationService.activateTickets(this.tickets).subscribe(activation => {
      // Acativation successful
      this.activationInProgress = false;
      this.toastrService.success('Alle Tickets wurden aktiviert', 'Tickets aktiviert');
      this.tickets = [];
      this.ticketTable.renderRows();
    }, error => {
      // Activation fails
      this.activationInProgress = false;
      let errorMsg = '';

      if (error.status === 409) {
        const ticketId = error.error.id;
        errorMsg = 'Das Ticket ' + ticketId + ' ist bereits verkauft.';
      }

      if (error.status === 418) {
        errorMsg = 'Die Vorstellung wäre mit den Tickets überbucht';
      }

      this.toastrService.error(errorMsg, 'Aktivierung fehlgeschlagen');
    });
  }

  public calculateTotalCost(): number {
    let cost = 0;
    for (const ticket of this.tickets) {
      cost += ticket.price;
    }
    return cost;
  }

  public getTotalTickets(): string {
    let childCount = 0;
    let adultCount = 0;

    for (const ticket of this.tickets) {
      switch (ticket.visitor) {
        case 'CHILD':
          childCount++;
          continue;
        case 'ADULT':
          adultCount++;
          continue;
      }
    }

    if (this.deviceDetector.isMobile()) {
      return this.tickets.length + '';
    } else {
      return this.tickets.length + ' (' + childCount + 'K / ' + adultCount + 'E)';
    }
  }

  public convertPrice(price: number): string {
    if (this.deviceDetector.isMobile()) {
      return Converter.shortPriceConverter(price);
    } else {
      return Converter.priceConverter(price);
    }
  }

  public convertVisitor(visitor: string): string {
    if (this.deviceDetector.isMobile()) {
      return Converter.shortVisitorConverter(visitor);
    } else {
      return Converter.visitorConverter(visitor);
    }
  }

  public convertShowDate(show: Show): string {
    if (this.deviceDetector.isMobile()) {
      return Converter.shortShowDateConverter(show.date);
    } else {
      return Converter.showDateConverter(show.date);
    }
  }

  public convertTicketId(ticketId: string): string {
    if (this.deviceDetector.isMobile()) {
      return Validator.calculateCheckSum(ticketId);
    } else {
      return ticketId;
    }
  }

}
