import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Ticket } from 'src/app/models/ticket';
import { Show } from 'src/app/models/show';
import { AddTicketDialogData } from 'src/app/models/add-ticket-dialog-data';
import { BaseDataService } from 'src/app/services/base-data.service';
import { Price } from 'src/app/models/price';
import { Converter } from 'src/app/utils/converter';
import { BarcodeScannerComponent } from '../barcode-scanner/barcode-scanner.component';
import { FormControl, Validators } from '@angular/forms';
import { Validator } from 'src/app/utils/validator';
import { TicketService } from 'src/app/services/ticket.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-add-ticket-dialog',
  templateUrl: './add-ticket-dialog.component.html',
  styleUrls: ['./add-ticket-dialog.component.scss']
})
export class AddTicketDialogComponent {

  public ticket: Ticket;

  public shows: Show[];
  public prices: Price[];

  ticketFormControl = new FormControl('', [
    Validators.required,
    Validators.minLength(7),
    Validators.maxLength(7),
    Validator.ticketIdValidator(),
    Validator.ticketIdAlreadyAdded(this.data.sellOverview)
  ]);

  constructor(private dialogRef: MatDialogRef<AddTicketDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: AddTicketDialogData,
              private baseDataService: BaseDataService,
              private dialogHandler: MatDialog) {
    this.ticket = new Ticket();
    this.ticket.show = data.show;
    this.ticket.visitor = 'ADULT';

    baseDataService.getShows().subscribe(shows => this.shows = shows);
    baseDataService.getPrices().subscribe(prices => this.prices = prices);

    this.ticketFormControl.valueChanges.subscribe(value => this.ticket.id = value);
  }

  doScan() {
    const scanDialog = this.dialogHandler.open(BarcodeScannerComponent, {
      width: '80%',
      disableClose: true
    });

    scanDialog.afterClosed().subscribe(code => {
      if (code) {
        this.ticketFormControl.setValue(code);
      }
    });
  }

  onCancelClick(): void {
    this.dialogRef.close();
  }

  onAddClick(): void {
    for (const price of this.prices) {
      if (price.visitor === this.ticket.visitor) {
        this.ticket.price = price.amount;
      }
    }

    this.dialogRef.close(this.ticket);
  }

  isDialogReady(): boolean {
    if (!this.ticket.id) {
      return false;
    }

    if (!this.ticketFormControl.valid) {
      return false;
    }

    if (!this.ticket.show) {
      return false;
    }

    if (!this.ticket.visitor) {
      return false;
    }

    return true;
  }

  public convertVisitor(visitor: string): string {
    return Converter.visitorConverter(visitor);
  }

  public convertShowDate(show: Show): string {
    return Converter.showDateConverter(show.date);
  }

}
