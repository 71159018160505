import { Show } from './show';
import { Activation } from './activation';

export class Ticket {

  id: number;
  activations: Activation[];
  show: Show;
  visitor: string;
  comment: string;
  checks: number;
  price: number;

}
