<mat-toolbar>
  <mat-toolbar-row>
    <button mat-icon-button aria-label="Verkaufen" (click)="this.router.navigate(['sell'])" *ngIf="this.showSellerOptions()">
      <mat-icon>shopping_cart</mat-icon>
    </button>
    <button mat-icon-button aria-label="Kassenprüfung" (click)="this.router.navigate(['cashaudit'])" *ngIf="this.showSellerOptions()">
      <mat-icon>euro</mat-icon>
    </button>
    <span class="toolbar-spacer" *ngIf="this.showSellerOptions()"></span>
    <span class="toolbar-spacer"></span>
    <button mat-icon-button aria-label="Profil" (click)="this.router.navigate(['profile'])">
      <mat-icon>person</mat-icon>
    </button>
  </mat-toolbar-row>
</mat-toolbar>

<br>

<router-outlet></router-outlet>
