import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from './config.service';
import { User } from '../models/user';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient, private configService: ConfigService) { }

  getUsers(): Observable<User[]> {
    return this.http.get<User[]>(this.configService.getUrl() + '/users', this.configService.getHeader());
  }

  getApiKey(user: User, pin: string): Observable<User> {
    return this.http.get<User>(this.configService.getUrl() + '/users/' + user.id + '/key', this.configService.getPinHeader(pin));
  }

}
