import { Component, OnInit, OnDestroy } from '@angular/core';
import { of, Observable, Subject } from 'rxjs';
import { MatDialogRef } from '@angular/material/dialog';

declare var Quagga: any;

@Component({
  selector: 'app-barcode-scanner',
  templateUrl: './barcode-scanner.component.html',
  styleUrls: ['./barcode-scanner.component.scss']
})
export class BarcodeScannerComponent implements OnInit, OnDestroy {

  public static scans: string[] = [];

  public static code: Subject<string> = new Subject();

  public error: string;

  constructor(private dialogRef: MatDialogRef<BarcodeScannerComponent>) { }

  ngOnInit(): void {
    Quagga.init({
      inputStream : {
        name : 'BarcodeScanner',
        target: document.querySelector('#videoOutput')
      },
      frequency: 25,
      decoder : {
        readers : ['code_128_reader'],
        multiple: false
      }
    }, (err) => {
        if (err) {
            this.error = err;
            return;
        }
        Quagga.start();
    });

    Quagga.onProcessed(this.onProcessed);

    BarcodeScannerComponent.code.subscribe(code => this.finishScan(code));
  }

  finishScan(code: string) {
    this.dialogRef.close(code);
    this.ngOnDestroy();
  }

  ngOnDestroy(): void {
    Quagga.stop();
  }

  onProcessed(data: any) {
    if (data && data.codeResult && data.codeResult.code && data.codeResult.startInfo && data.codeResult.startInfo.error === 0) {
      BarcodeScannerComponent.scans.push(data.codeResult.code);
    }

    // Wait for at least 15 successful scans
    if (!BarcodeScannerComponent.scans || BarcodeScannerComponent.scans.length < 5) {
      return;
    }


    const a = [];
    const b = [];
    let prev: string;

    BarcodeScannerComponent.scans.sort();
    for ( const scan of BarcodeScannerComponent.scans ) {
        if ( scan !== prev ) {
            a.push(scan);
            b.push(1);
        } else {
            b[b.length - 1]++;
        }
        prev = scan;
    }

    let highestIndex = 0;
    let highest = 0;
    for (let i = 0; i < b.length; i++) {
      if (b[i] > highest) {
        highestIndex = i;
        highest = b[i];
      }
    }

    BarcodeScannerComponent.code.next(a[highestIndex]);
    BarcodeScannerComponent.scans = [];
  }

}
