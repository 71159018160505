import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';
import { HttpClient } from '@angular/common/http';
import { Ticket } from '../models/ticket';
import { Observable } from 'rxjs';
import { Activation } from '../models/activation';
import { User } from '../models/user';

@Injectable({
  providedIn: 'root'
})
export class ActivationService {

  constructor(private configService: ConfigService, private http: HttpClient) { }

  activateTickets(tickets: Ticket[]): Observable<Activation> {
    return this.http.post<Activation>(this.configService.getUrl() + '/activations', tickets, this.configService.getHeader());
  }

  getUserActivations(user: User): Observable<Activation[]> {
    return this.http.get<Activation[]>(this.configService.getUrl() + '/users/' + user.id + '/activations', this.configService.getHeader());
  }
}
