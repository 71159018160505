<mat-card class="width">
  <mat-card-content>
    <mat-progress-spinner class="spinner" color="primary" mode="indeterminate" *ngIf="this.activationInProgress">
    </mat-progress-spinner>

    <div class="fullWidth" align="right" *ngIf="!this.activationInProgress">
      <mat-checkbox
          [(ngModel)]="payCheck"
          labelPosition="before">
        Zahlung erhalten?
      </mat-checkbox>
    </div>
  </mat-card-content>
  <mat-card-actions *ngIf="!this.activationInProgress">
    <button mat-raised-button [disabled]="!this.payCheck || this.tickets.length < 1" color="primary" (click)="this.activateTickets()" class="fullWidth">Tickets aktivieren</button>
  </mat-card-actions>
</mat-card>

<br>

<mat-card class="width marginBottom">
  <mat-card-header>
    <mat-card-title>
      Tickets
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <table mat-table [dataSource]="this.tickets" class="mat-elevation-z8 ticketTable">
      <ng-container matColumnDef="show">
        <th mat-header-cell *matHeaderCellDef>Tag</th>
        <td mat-cell *matCellDef="let ticket">{{this.convertShowDate(ticket.show)}}</td>
        <td mat-footer-cell *matFooterCellDef>Gesamt</td>
      </ng-container>

      <ng-container matColumnDef="visitor">
        <th mat-header-cell *matHeaderCellDef>Gast</th>
        <td mat-cell *matCellDef="let ticket">{{this.convertVisitor(ticket.visitor)}}</td>
        <td mat-footer-cell *matFooterCellDef>{{this.getTotalTickets()}}</td>
      </ng-container>

      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef>Ticket</th>
        <td mat-cell *matCellDef="let ticket">{{this.convertTicketId(ticket.id)}}</td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <ng-container matColumnDef="price">
        <th mat-header-cell *matHeaderCellDef>Preis</th>
        <td mat-cell *matCellDef="let ticket">{{this.convertPrice(ticket.price)}}</td>
        <td mat-footer-cell *matFooterCellDef>{{this.convertPrice(this.calculateTotalCost())}}</td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let ticket">
          <button *ngIf="!this.payCheck" mat-icon-button aria-label="Remove ticket" (click)="this.removeTicket(ticket)">
            <mat-icon>delete</mat-icon>
          </button>
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
    </table>
  </mat-card-content>
</mat-card>

<button mat-fab aria-label="Ticket hinzufügen" (click)="this.openTicketDialog()" color="primary" class="fab">
  <mat-icon>add_shopping_cart</mat-icon>
</button>
