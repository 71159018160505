import { Component, OnInit } from '@angular/core';
import { Activation } from 'src/app/models/activation';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { ActivationService } from 'src/app/services/activation.service';
import { LoginService } from 'src/app/services/login.service';
import { Converter } from 'src/app/utils/converter';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Show } from 'src/app/models/show';

export class Counts {
  childCount: number;
  adultCount: number;
  totalCount: number;

  constructor() {
    this.adultCount = 0;
    this.childCount = 0;
    this.totalCount = 0;
  }
}

@Component({
  selector: 'app-cashaudit',
  templateUrl: './cashaudit.component.html',
  styleUrls: ['./cashaudit.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class CashauditComponent implements OnInit {

  public dataSource: Activation[] = [];
  public columnsToDisplay = ['id', 'date', 'tickets', 'amount'];
  public expandedElement: Activation | null;

  public loadingInProgress = false;

  constructor(private activationService: ActivationService,
              private loginService: LoginService,
              private deviceDetector: DeviceDetectorService) { }

  ngOnInit(): void {
    this.loadingInProgress = true;
    this.activationService.getUserActivations(this.loginService.getLoggedIn()).subscribe(activations => {
      this.dataSource = activations;
      this.loadingInProgress = false;
    });
  }

  public convertShowDate(date: string): string {
    if (this.deviceDetector.isMobile()) {
      return Converter.shortShowDateConverter(date);
    } else {
      return Converter.showDateConverter(date);
    }
  }

  public convertPrice(price: number): string {
    if (this.deviceDetector.isMobile()) {
      return Converter.shortPriceConverter(price);
    } else {
      return Converter.priceConverter(price);
    }
  }

  public calculateTotalCost(): number {
    let cost = 0;
    for (const activation of this.dataSource) {
      cost += activation.amount;
    }
    return cost;
  }

  public getTotalTickets(): Counts {
    const counts = new Counts();

    for (const activation of this.dataSource) {
      const result = this.getActivationTickets(activation);
      counts.totalCount += result.totalCount;
      counts.childCount += result.childCount;
      counts.adultCount += result.adultCount;
    }

    return counts;
  }

  public getActivationTickets(activation: Activation): Counts {
    const counts = new Counts();

    counts.totalCount = activation.tickets.length;
    for (const ticket of activation.tickets) {
      switch (ticket.visitor) {
        case 'CHILD':
          counts.childCount++;
          continue;
        case 'ADULT':
          counts.adultCount++;
          continue;
      }
    }
    return counts;
  }

  public countsToString(counts: Counts): string {
    if (this.deviceDetector.isMobile()) {
      return counts.totalCount + '';
    } else {
      return counts.totalCount + ' (' + counts.childCount + 'K / ' + counts.adultCount + 'E)';
    }
  }
}
