import { Injectable, OnInit } from '@angular/core';
import { User } from '../models/user';
import { BaseDataService } from './base-data.service';
import { LoginListener } from '../interfaces/login-listener';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  private userKey = 'loggedInUser';

  private loggedInUser: User = null;

  private loginListeners: LoginListener[] = [];

  constructor() {
    this.loggedInUser = JSON.parse(localStorage.getItem(this.userKey));
  }

  public setLoggedIn(user: User) {
    localStorage.setItem(this.userKey, JSON.stringify(user));
    this.loggedInUser = user;
    this.notifyListeners();
  }

  private notifyListeners() {
    for (const listener of this.loginListeners) {
      listener.onLogin(this.loggedInUser);
    }
  }

  public isLoggedIn(): boolean {
    return this.loggedInUser ? true : false;
  }

  public getLoggedIn(): User {
    return this.loggedInUser;
  }

  public hasRole(role: string): boolean {
    if (!this.isLoggedIn()) {
      return false;
    }

    for (const r of this.loggedInUser.roles) {
      if (role === r.name) {
        return true;
      }
    }

    return false;
  }

  public addLoginListener(listener: LoginListener) {
    this.loginListeners.push(listener);
    listener.onLogin(this.loggedInUser);
  }

  public removeLoginListener(listener: LoginListener) {
    const index = this.loginListeners.indexOf(listener);
    if (index > -1) {
      this.loginListeners.splice(index, 1);
    }
  }
}
