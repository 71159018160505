import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { ConfigService } from './config.service';
import { Show } from '../models/show';
import { Price } from '../models/price';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ThrowStmt } from '@angular/compiler';
import { LoginListener } from '../interfaces/login-listener';
import { LoginService } from './login.service';
import { User } from '../models/user';

@Injectable({
  providedIn: 'root'
})
export class BaseDataService implements LoginListener, OnDestroy {

  private shows: Show[];
  private prices: Price[];

  constructor(private http: HttpClient, private configService: ConfigService, private loginService: LoginService) {
    loginService.addLoginListener(this);
  }

  ngOnDestroy(): void {
    this.loginService.removeLoginListener(this);
  }

  onLogin(user: User): void {
    if (user) {
      this.getShows(false).subscribe(shows => this.shows = shows);
      this.getPrices(false).subscribe(prices => this.prices = prices);
    }
  }

  getShows(allowCache: boolean = true): Observable<Show[]> {
    if (this.shows && allowCache) {
      return of(this.shows);
    }

    const response = this.http.get<Show[]>(this.configService.getUrl() + '/shows', this.configService.getHeader());
    response.subscribe(shows => this.shows = shows);
    return response;
  }

  getPrices(allowCache: boolean = true): Observable<Price[]> {
    if (this.prices && allowCache) {
      return of(this.prices);
    }

    const response = this.http.get<Price[]>(this.configService.getUrl() + '/prices', this.configService.getHeader());
    response.subscribe(prices => this.prices = prices);
    return response;
  }

}
