<mat-progress-spinner class="spinner" color="primary" mode="indeterminate" *ngIf="this.loadingInProgress">
</mat-progress-spinner>

<mat-card class="card">
  <mat-card-content>
    <table mat-table [dataSource]="dataSource" class="fullWidth mat-elevation-z8">
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef>ID</th>
        <td mat-cell *matCellDef="let element"> {{element.id}} </td>
        <td mat-footer-cell *matFooterCellDef>Gesamt</td>
      </ng-container>

      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef>Datum</th>
        <td mat-cell *matCellDef="let element"> {{this.convertShowDate(element.date)}} </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <ng-container matColumnDef="tickets">
        <th mat-header-cell *matHeaderCellDef>Tickets</th>
        <td mat-cell *matCellDef="let element"> {{this.countsToString(this.getActivationTickets(element))}} </td>
        <td mat-footer-cell *matFooterCellDef>{{this.countsToString(this.getTotalTickets())}}</td>
      </ng-container>

      <ng-container matColumnDef="amount">
        <th mat-header-cell *matHeaderCellDef>Preis</th>
        <td mat-cell *matCellDef="let element"> {{this.convertPrice(element.amount)}} </td>
        <td mat-footer-cell *matFooterCellDef>{{this.convertPrice(this.calculateTotalCost())}}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
      <tr mat-footer-row *matFooterRowDef="columnsToDisplay"></tr>
      <tr mat-row *matRowDef="let row; columns: columnsToDisplay" class="example-detail-row"></tr>
    </table>
  </mat-card-content>
</mat-card>

<br>
