<mat-card class="login">
  <mat-card-content *ngIf="!this.isLoggedIn()">
    <mat-form-field class="input">
      <mat-label>Nutzername</mat-label>
      <mat-select [(ngModel)]="this.selectedUser">
        <mat-option *ngFor="let user of this.users" [value]="user">
          {{user.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="input">
      <mat-label>PIN</mat-label>
      <input type="password" matInput [(ngModel)]="this.enteredPin" placeholder="PIN">
    </mat-form-field>

    <button type="submit" class="input" mat-raised-button color="primary" (click)="this.loginUser()">Anmelden</button>
  </mat-card-content>

  <mat-card-content *ngIf="this.isLoggedIn()">
    <button class="input" mat-raised-button color="primary" (click)="this.logoutUser()">Abmelden</button>

    <br><br><br>

    <app-profile-details></app-profile-details>
  </mat-card-content>
</mat-card>
