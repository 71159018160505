<h1 mat-dialog-title>Ticket konfigurieren</h1>
<div mat-dialog-content>
  <mat-form-field>
    <mat-label>Ticket ID</mat-label>
    <input matInput [formControl]="ticketFormControl">
    <button mat-icon-button (click)="this.doScan()" matSuffix aria-label="Scannen"><mat-icon>camera_alt</mat-icon></button>
    <mat-error *ngIf="ticketFormControl.hasError('required')">
      Ticket ID wird benötigt
    </mat-error>
    <mat-error *ngIf="ticketFormControl.hasError('minlength') || ticketFormControl.hasError('maxlength')">
      Ticket ID muss 7 Zeichen lang sein
    </mat-error>
    <mat-error *ngIf="!ticketFormControl.hasError('required') &&
                      !ticketFormControl.hasError('minlength') &&
                      !ticketFormControl.hasError('maxlength') &&
                      ticketFormControl.hasError('ticketInvalid')">
      Format der Ticket ID ist nicht korrekt
    </mat-error>
    <mat-error *ngIf="!ticketFormControl.hasError('required') &&
                      !ticketFormControl.hasError('minlength') &&
                      !ticketFormControl.hasError('maxlength') &&
                      !ticketFormControl.hasError('ticketInvalid') &&
                      ticketFormControl.hasError('ticketAlreadyAdded')">
      Ticket wird bereits verwendet
    </mat-error>
  </mat-form-field>

  <br>
  <mat-label id="showLabel">Vorstellung:</mat-label>
  <mat-radio-group aria-labelledby="showLabel" class="radioButtonGroup" [(ngModel)]="this.ticket.show">
    <mat-radio-button *ngFor="let show of this.shows" class="radioButton" [value]="show">
      {{this.convertShowDate(show)}}
    </mat-radio-button>
  </mat-radio-group>

  <br>
  <mat-label id="showLabel">Preiskategorie:</mat-label>
  <mat-radio-group aria-labelledby="showLabel" class="radioButtonGroup" [(ngModel)]="this.ticket.visitor">
    <mat-radio-button *ngFor="let price of this.prices" class="radioButton" [value]="price.visitor">
      {{this.convertVisitor(price.visitor)}}
    </mat-radio-button>
  </mat-radio-group>
</div>
<div mat-dialog-actions>
  <button mat-icon-button (click)="this.onCancelClick()" aria-label="Abbrechen"><mat-icon>block</mat-icon></button>
  <span class="action-spacer"></span>
  <button mat-icon-button (click)="this.onAddClick()" color="primary" [disabled]="!this.isDialogReady()" aria-label="Hinzufügen"><mat-icon>add_circle_outline</mat-icon></button>
</div>
