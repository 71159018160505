import { tick } from '@angular/core/testing';
import { ValidatorFn, AbstractControl } from '@angular/forms';
import { SellOverviewComponent } from '../components/sell-overview/sell-overview.component';

export class Validator {

  static validateCheckSum(ticketId: string, checkSum: string): boolean {
      const digit: string = Validator.addAllNumbers(checkSum);

      if (digit === ticketId.substring(3, 4)) {
        return true;
      }

      return false;
  }

  static validateTitcketId(ticketId: string): boolean {
    if (!ticketId || ticketId.length !== 7) {
      return false;
    }

    const checkSum = this.calculateCheckSum(ticketId);

    return this.validateCheckSum(ticketId, checkSum);
  }

  static addAllNumbers(input: string): string {
    let output = 0;

    let counter: number = input.length;
    while (counter--) {
      // tslint:disable-next-line: radix
      output += parseInt(input.charAt(counter));
    }

    let outString: string = output.toString();
    if (output > 9) {
      outString = this.addAllNumbers(outString);
    }

    return outString;
  }

  static ticketIdValidator(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      const valid = this.validateTitcketId(control.value);
      return !valid ? {ticketInvalid: true} : null;
    };
  }

  static ticketIdAlreadyAdded(sellOverview: SellOverviewComponent): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      const alreadyAdded = sellOverview.isTicketAlreadyAdded(control.value);
      return alreadyAdded ? {ticketAlreadyAdded: true} : null;
    };
  }

  static calculateCheckSum(ticketId: string): string {
    const part1 = ticketId.substring(0, 1) + ticketId.substring(6, 7);
    const part2 = ticketId.substring(1, 2) + ticketId.substring(5, 6);
    const part3 = ticketId.substring(2, 3) + ticketId.substring(4, 5);

    let checkSum = this.addAllNumbers(part1);
    checkSum += this.addAllNumbers(part2);
    checkSum += this.addAllNumbers(part3);

    return checkSum;
  }
}
