import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { UserService } from './user.service';
import { LoginService } from './login.service';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  private url = 'https://api.theatergruppe-wedemark.de';

  constructor(private loginService: LoginService) { }

  public getUrl(): string {
    return this.url;
  }

  public getHeader() {
    if (this.loginService.isLoggedIn()) {
      return this.getKeyHeader();
    }

    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
  }

  private getKeyHeader() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        key: this.loginService.getLoggedIn().apiKey
      })
    };
  }

  public getPinHeader(pin: string) {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        pin
      })
    };
  }

}
