<mat-card>
  <mat-card-header>
    <mat-card-title>
      Profilinformationen
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <mat-list>
      <h3 mat-subheader>Name</h3>
      <mat-list-item>
        {{this.user.name}}
      </mat-list-item>
      <mat-divider></mat-divider>
      <h3 mat-subheader>Rollen</h3>
      <mat-list-item *ngFor="let role of this.user.roles">
        <h4 mat-line>{{role.name}}</h4>
      </mat-list-item>
    </mat-list>
  </mat-card-content>
</mat-card>
