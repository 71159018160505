import { Show } from '../models/show';
import { formatDate } from '@angular/common';

export class Converter {

  public static priceConverter(price: number): string {
    return (Math.round(price * 100) / 100).toFixed(2).replace('.', ',') + '€';
  }

  public static shortPriceConverter(price: number): string {
    const result = (Math.round(price * 100) / 100);
    if (result * 100 % 100 === 0) {
      return result.toFixed(0) + '€';
    } else {
      return this.priceConverter(price);
    }
  }

  public static visitorConverter(visitor: string): string {
    switch (visitor) {
      case 'ADULT': return 'Erwachsener';
      case 'CHILD': return 'Kind';
      default: return 'ERROR';
    }
  }

  public static shortVisitorConverter(visitor: string): string {
    switch (visitor) {
      case 'ADULT': return 'E';
      case 'CHILD': return 'K';
      default: return 'ERROR';
    }
  }

  public static showDateConverter(date: string): string {
    return formatDate(date, 'EEEEEE dd.MM.yy', 'DE');
  }

  public static shortShowDateConverter(date: string): string {
    return formatDate(date, 'MMM EEEEEE', 'DE');
  }

}
